<template>
  <div class="all" id="all">
    <Home2Header active-index="/news"></Home2Header>
    <NewsHeader2/>
    <div id="main">
      <iframe :src="address" id="ifm" width="100%"></iframe>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Home2Header from "../../components/Home2Header";
import NewsHeader2 from "../../components/NewsHeader2";
import Footer from "../../components/Footer";

export default {
  name: "News2",
  data() {
    return {
      address: null,
      height: null,
    }
  },
  components: {
    Home2Header, NewsHeader2, Footer
  },
  props: {
    activeIndex: {
      type: String
    }
  },
  computed: {},
  methods: {},
  created() {
    this.address = this.$route.query.address;
  },
  mounted() {
    let ifm = document.getElementById("ifm");
    let main=document.getElementById('main');
    ifm.height = document.documentElement.scrollHeight;
    main.style.height= ifm.height + 'px';
    // console.log(main.style.height)
  }
}
</script>

<style scoped>
.all {
  min-width: 960px;
}
</style>